// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-acquista-jsx": () => import("./../../../src/pages/Acquista.jsx" /* webpackChunkName: "component---src-pages-acquista-jsx" */),
  "component---src-pages-components-author-image-jsx": () => import("./../../../src/pages/components/AuthorImage.jsx" /* webpackChunkName: "component---src-pages-components-author-image-jsx" */),
  "component---src-pages-components-brand-image-jsx": () => import("./../../../src/pages/components/BrandImage.jsx" /* webpackChunkName: "component---src-pages-components-brand-image-jsx" */),
  "component---src-pages-components-hero-image-jsx": () => import("./../../../src/pages/components/HeroImage.jsx" /* webpackChunkName: "component---src-pages-components-hero-image-jsx" */),
  "component---src-pages-cover-jsx": () => import("./../../../src/pages/Cover.jsx" /* webpackChunkName: "component---src-pages-cover-jsx" */),
  "component---src-pages-da-zero-al-brand-jsx": () => import("./../../../src/pages/DaZeroAlBrand.jsx" /* webpackChunkName: "component---src-pages-da-zero-al-brand-jsx" */),
  "component---src-pages-gli-autori-jsx": () => import("./../../../src/pages/GliAutori.jsx" /* webpackChunkName: "component---src-pages-gli-autori-jsx" */),
  "component---src-pages-gli-eroi-jsx": () => import("./../../../src/pages/GliEroi.jsx" /* webpackChunkName: "component---src-pages-gli-eroi-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quote-jsx": () => import("./../../../src/pages/Quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */)
}

